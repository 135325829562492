import React from 'react';

const Content = (props) => {

    return(
        <div>
            {props.children}
        </div>
    );
};

export default Content;